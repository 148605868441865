import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const DropdownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        style={{ transform: 'translateY(-6px) !important' }}
        d="M7 10l5 5 5-5z"
        fill="#0075AA"
      />
    </SvgIcon>
  );
};

export default DropdownIcon;
