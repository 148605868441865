import React, { useRef } from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { NAVIGATION_ITEMS } from '@stewart/common-ui/components/StewartHeader';
import { useMobileNavbarStyles } from '@stewart/common-ui/components/StewartHeader/components/MobileNavbar/style';
import NavigationItem from '@stewart/common-ui/components/StewartHeader/components/NavigationItem';
import { NavigationItemPropsType } from '@stewart/common-ui/components/StewartHeader/components/NavigationItem/models';
import EditProfileDialog from '@stewart/common-ui/dialogs/EditProfileDialog';
import { EditProfileDialogRefType } from '@stewart/common-ui/dialogs/EditProfileDialog/models';
import { PermissionStore } from '@stewart/core/redux/reducers/permission.reducer';
import { RootStateType } from '@stewart/core/redux/redux.models';

import { StewartMobileNavbarPropsTypes } from './models';

export default function StewartMobileNavbar({
  isMobileOpen,
  setMobileOpen,
}: StewartMobileNavbarPropsTypes): ReactJSXElement {
  const classes = useMobileNavbarStyles();
  const { permissionCodes } = useSelector<RootStateType, PermissionStore>(
    (state) => state.permission
  );
  const editProfileDialogRef = useRef<EditProfileDialogRefType>(null);
  const list = () => (
    <Box className={classes.navbarList}>
      <List
        sx={{
          border: '1px solid transparent',
          paddingTop: '0 !important',
          paddingBottom: '0 !important',
        }}
      >
        {NAVIGATION_ITEMS.filter((data: NavigationItemPropsType) =>
          permissionCodes.includes(data.permissionCode)
        ).map((data: NavigationItemPropsType): any => (
          <NavigationItem key={uuidv4()} {...data} closeMenu={setMobileOpen} />
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <Drawer
        PaperProps={{ className: classes.mobileNavbar }}
        anchor="right"
        open={isMobileOpen}
        onClose={() => setMobileOpen(false)}
      >
        {list()}
      </Drawer>
      <EditProfileDialog ref={editProfileDialogRef} />
    </>
  );
}
