import React, { useEffect } from 'react';

import { EventMessage, EventType, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { Box } from '@mui/material';

import { AuthenticatedApp } from '@stewart/common-ui/components/AuthenticatedApp';
import StewartFooter from '@stewart/common-ui/components/StewartFooter';
import StewartHeader from '@stewart/common-ui/components/StewartHeader';
import { useAuth } from '@stewart/core/contexts/Auth';
import Router from '@stewart/core/routing/Router';
import { ROOT_ROUTES } from '@stewart/core/routing/routes/root.routes';

function App() {
  const { loginRequest, passwordResetRequest, instance } = useAuth();

  useEffect(() => {
    const callbackId = instance.addEventCallback(async (eventMessage: EventMessage) => {
      if (eventMessage.eventType === EventType.LOGIN_FAILURE) {
        if (eventMessage.error?.message.includes('AADB2C90118')) {
          await instance.loginRedirect(passwordResetRequest);
        }

        if (eventMessage.error?.message.includes('no_cached_authority_error')) {
          window.location.reload();
        }

        if (eventMessage.error?.message.includes('AADB2C90091')) {
          await instance.loginRedirect(loginRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, loginRequest, passwordResetRequest]);

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
      >
        <AuthenticatedApp>
          <Box>
            <Box
              style={{
                minHeight: 'calc(100vh - 135px)',
                backgroundColor: '#E5E5E5',
              }}
            >
              <StewartHeader />
              <Router routes={ROOT_ROUTES} />
            </Box>
            <StewartFooter />
          </Box>
        </AuthenticatedApp>
      </MsalAuthenticationTemplate>
    </>
  );
}

export default App;
