import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { gradients } from '@stewart/theme/colors';

export const useStewartDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflowWrap: 'anywhere',
      padding: '12px !important',
      fontWeight: `700 !important`,
      fontSize: '14pt',
    },
    titleContainer: {
      height: '80px',
      margin: '-40px auto 0 auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      width: 'calc(100% - 26px)',
      fontSize: '24px',
      borderRadius: '8px !important',
      '& h2.MuiTypography-root': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
    },
    success: {
      background: gradients.successGreenGradient,
    },
    error: {
      background: gradients.errorGradient,
    },
    info: {
      background: gradients.darkGreyGradient,
    },
    warning: {
      background: gradients.yellowWarningGradient,
    },
    dialogPaper: {
      borderRadius: '12px!important',
      minWidth: '20vw',
      maxWidth: 'none!important',
      overflowY: 'revert !important' as 'revert',
    },
    staticDialogPaper: {
      height: 'auto !important',
      maxHeight: 'calc(100vh - 50px) !important',
      minHeight: '300px !important',
      marginTop: '60px !important',
    },
    closeIcon: {
      height: '50px',
      width: '50px',
      color: `${theme.palette.common.white}!important`,
      marginRight: '-20px!important',
    },
    action: {
      justifyContent: 'center!important',
      padding: '20px 24px!important',
    },
    customAction: {
      bottom: '5px !important',
      justifyContent: 'center !important',
      position: 'absolute !important' as any,
      width: '100% !important',
    },
    contentContainer: {
      fontFamily: 'Roboto !important',
      overflowY: 'auto !important' as any,
    },
    dualScrollbarContainer: {
      maxHeight: 'calc(100vh - 260px) !important',
    },
    contentBlockStyle: {
      display: 'flex !important',
      flexDirection: 'row !important' as any,
      justifyContent: 'end !important',
      position: 'relative !important' as any,
      top: '8px !important',
    },
    wrapper: {
      position: 'relative !important' as any,
    },
  })
);
