import React, { useEffect, useState } from 'react';

import { Configuration, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Box, ThemeProvider } from '@mui/material';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';

import StewartPreloader from '@stewart/common-ui/components/StewartPreloader';
import StewartErrorDialog from '@stewart/common-ui/dialogs/StewartErrorDialog';
import StewartSuccessDialog from '@stewart/common-ui/dialogs/StewartSuccessDialog';
import { AuthProvider } from '@stewart/core/contexts/Auth';
import { ComponentRefsProvider } from '@stewart/core/contexts/ComponentRefs';
import { DeactivatedAccountProvider } from '@stewart/core/contexts/DeactivatedAccount';
import { InfoDialogsProvider } from '@stewart/core/contexts/InfoDialogs';
import Interceptors from '@stewart/core/http/interceptors';
import { Nullable } from '@stewart/core/models';
import { AuthConfigType } from '@stewart/core/rest/models/configuration.models';
import { getConfiguration } from '@stewart/core/rest/requests/configuration.rest';
import Router from '@stewart/core/routing/Router';
import { OUTSIDE_ROUTES } from '@stewart/core/routing/routes/outside.routes';
import { apiUrlStorage } from '@stewart/core/services/apiUrlStorage.service';
import { policyWebUrlStorage } from '@stewart/core/services/policyWebUrlStorage.service';
import { scMainWebUrlStorage } from '@stewart/core/services/scMainWebUrlStorage.service';
import { signalRUrl } from '@stewart/core/services/signalRStorage.service';
import customTheme from '@stewart/theme';

interface ConfigurationAppProps {
  history: History;
}

export function ConfigurationApp({ history }: ConfigurationAppProps): ReactJSXElement {
  const [instance, setInstance] = useState<Nullable<PublicClientApplication>>(null);
  const [loginRequest, setLoginRequest] = useState<Nullable<RedirectRequest>>(null);
  const [passwordResetRequest, setPasswordResetRequest] = useState<Nullable<RedirectRequest>>(null);
  const [subscriptionKey, setSubscriptionKey] = useState<Nullable<string>>(null);

  /**
   * Return Composed Msal Config
   * @param {AuthConfigType} data
   * @return {Configuration}
   */
  function getMsalConfig(data: AuthConfigType): Configuration {
    const { REACT_APP_MSAL_CLIENT_ID, REACT_APP_MSAL_AUTHORITY, REACT_APP_KNOWN_AUTHORITY } = data;

    return {
      auth: {
        clientId: REACT_APP_MSAL_CLIENT_ID,
        authority: REACT_APP_MSAL_AUTHORITY,
        knownAuthorities: [REACT_APP_KNOWN_AUTHORITY],
        redirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      },
      system: {},
    };
  }

  useEffect(() => {
    getConfiguration().then((data: AuthConfigType) => {
      const {
        REACT_APP_KNOWN_SCOPE,
        REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
        REACT_APP_API_URL,
        RESET_PASSWORD_AUTHORITY,
        SIGNALR_APP_API_URL,
        SC_MAIN_WEB_URL,
        PSP_WEB_URL,
      } = data;

      setLoginRequest({
        scopes: [REACT_APP_KNOWN_SCOPE],
      });
      setPasswordResetRequest({
        scopes: [REACT_APP_KNOWN_SCOPE],
        authority: RESET_PASSWORD_AUTHORITY,
      });
      setInstance(new PublicClientApplication(getMsalConfig(data)));
      setSubscriptionKey(REACT_APP_OCP_APIM_SUBSCRIPTION_KEY);
      apiUrlStorage.write(REACT_APP_API_URL);
      scMainWebUrlStorage.write(SC_MAIN_WEB_URL);
      policyWebUrlStorage.write(PSP_WEB_URL);
      signalRUrl.write(SIGNALR_APP_API_URL);
    });
  }, []);

  return (
    <Box>
      {instance && loginRequest && passwordResetRequest && subscriptionKey && (
        <InfoDialogsProvider>
          <ConnectedRouter history={history}>
            <ThemeProvider theme={customTheme}>
              <MsalProvider instance={instance}>
                <AuthProvider
                  loginRequest={loginRequest}
                  passwordResetRequest={passwordResetRequest}
                >
                  <DeactivatedAccountProvider>
                    <ComponentRefsProvider>
                      <Interceptors subscriptionKey={subscriptionKey}>
                        <Router routes={OUTSIDE_ROUTES} />
                        <StewartPreloader />
                        <StewartSuccessDialog />
                        <StewartErrorDialog />
                      </Interceptors>
                    </ComponentRefsProvider>
                  </DeactivatedAccountProvider>
                </AuthProvider>
              </MsalProvider>
            </ThemeProvider>
          </ConnectedRouter>
        </InfoDialogsProvider>
      )}
    </Box>
  );
}
