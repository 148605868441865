import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import colors, { gradients } from '@stewart/theme/colors';

export const useMobileNavbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileNavbar: {
      height: 'auto!important',
    },
    navbarList: {
      background: gradients.darkGreyGradient,
      paddingTop: '75px',
      width: '192px',
    },
    accordion: {
      backgroundColor: 'transparent!important',
      boxShadow: 'none!important',
    },
    profile: {
      width: '180px',
      height: '100%',
      color: theme.palette.common.white,

      '&.media': {
        '@media (max-width:1080px)': {
          display: 'flex',
          paddingLeft: '0',
        },
      },
    },
    profileIcon: {
      fontSize: '25px!important',
      marginRight: '5px',
      color: theme.palette.common.white,
    },
    profileActions: {
      display: 'flex',
      flexDirection: 'column',
    },
    profileActionButton: {
      '&.media': {
        color: colors.white,
        justifyContent: 'flex-start',
        fontWeight: '400',
      },
    },
    dropDownIcon: {
      color: colors.white,
    },
    line: {
      background: colors.white,
      marginTop: '20px!important',
    },
  })
);
