import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import colors from '@stewart/theme/colors';

export const useStewartInputStyles = makeStyles<Theme>(() => ({
  warning: {
    '& fieldset': {
      borderColor: `${colors.orange}!important`,
    },
    '&.warning-input:hover fieldset': {
      borderColor: `${colors.orange}!important`,
    },
  },
  error: {
    marginLeft: '12px!important',
    position: 'absolute',
    top: '35px',
    maxHeight: '30px',
    overflowY: 'auto',
  },
  label: {
    marginLeft: '-2px!important',
  },
  errorLabel: { color: 'rgb(224, 0, 0) !important' },
}));
