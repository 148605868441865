import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { gradients } from '@stewart/theme/colors';

export const useHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      zIndex: '1110',
      backgroundColor: 'transparent !important',
      boxShadow: 'none !important',
      '&.media': {
        '@media (max-width:1279px)': {
          zIndex: '1210',
        },
      },
    },
    toolbar: {
      alignItems: 'stretch!important',
      background: gradients.darkGreyGradient,
      borderBottomLeftRadius: '6px',
      borderBottomRightRadius: '6px',
      height: '75px !important',
      justifyContent: 'space-between',
    },
    logo: {
      height: '75px !important',
      marginRight: '20px!important',
      padding: '5px 0 !important',
      width: '157px',
    },
    profile: {
      alignItems: 'center !important',
      borderLeft: '1px solid #fff !important',
      borderRadius: '0 !important',
      color: theme.palette.common.white,
      flexDirection: 'row',
      height: '39px !important',
      marginTop: '-4px !important',
      minWidth: 'auto !important',
      padding: '5px 0 5px 24px !important',
      wordBreak: 'break-all',
    },
    profileContainer: {
      '&:before': {
        backgroundColor: '#333',
        bottom: '0',
        content: '',
        height: '60%',
        left: '1px',
        position: 'absolute',
        top: '0',
        width: '10px',
      },
    },
    profileIcon: {
      color: theme.palette.common.white,
      fontSize: '24px !important',
      marginRight: '5px',
    },
    menuContainer: {
      background: 'white',
      borderRadius: '4px',
      minWidth: '110px',
      overflow: 'inherit',
      zIndex: '2000',
      '&:after, &:before': {
        border: 'solid transparent',
        bottom: '100%',
        content: `""`,
        height: '0',
        left: '50%',
        pointerEvents: 'none',
        position: 'absolute',
        width: '0',
      },
      '&:after': {
        borderBottomColor: 'white',
        borderColor: 'transparent',
        borderWidth: '10px',
        marginLeft: '-10px',
      },
    },
    navigation: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: '-20px',
      navigationItems: {
        padding: '5px 10px',
      },
    },
    burger: {
      display: 'none',
      '@media (max-width:1279px)': {
        alignSelf: 'center !important',
        display: 'flex !important',
        marginLeft: 'auto !important',
        marginRight: '16px !important',
      },
    },
  })
);
