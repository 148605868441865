import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const useNavigationItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      minWidth: 'auto !important',
      padding: '13.5px 12px',
      position: 'relative',
      width: 'auto',
      '&.media': {
        '@media (max-width:1279px)': {
          flexDirection: 'row',
          padding: '12px 24px',
          justifyContent: 'left',
          width: '100%',
        },
      },
      '&:before': {
        backgroundColor: 'transparent',
        bottom: 0,
        content: '""',
        height: '2px',
        left: 0,
        position: 'absolute',
        width: '100%',
        transition: 'width .2s ease-in-out',
        '@media (max-width:1279px)': {
          height: '0',
          width: '0',
        },
      },
      '&:hover:before': {
        backgroundColor: theme.palette.common.white,
        '@media (max-width:1279px)': {
          height: '0',
          width: '0',
        },
      },
    },
    active: {
      '@media (max-width:1279px)': {
        backgroundColor: '#4d4c4cf7',
      },
      '&:before': {
        backgroundColor: theme.palette.common.white,
        '@media (max-width:1279px)': {
          width: '0',
          height: '0',
        },
      },
    },
    icon: {
      color: theme.palette.common.white,
      fontSize: '20px',
      height: '25px!important',
      width: '25px!important',
    },
    title: {
      color: theme.palette.common.white,
      fontSize: '14px',
      fontWeight: '700 !important',
      letterSpacing: '0.17px',
      lineHeight: '20.02px',
      position: 'relative',
      textTransform: 'none',
      '&.media': {
        '@media (max-width:1279px)': {
          marginLeft: '5px',
        },
      },
    },
  })
);
