import React, { useEffect } from 'react';

import { FormControl, FormHelperText, InputLabel, MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
// import { styled } from '@mui/material/styles';
// import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Field, getIn } from 'formik';
import { FieldAttributes } from 'formik/dist/Field';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import MaybeComponent from '@stewart/common-ui/components/MaybeComponent';
import DropdownIcon from '@stewart/common-ui/icons/DropdownIcon';
import { PermissionStore } from '@stewart/core/redux/reducers/permission.reducer';
import { RootStateType } from '@stewart/core/redux/redux.models';
import { label } from '@stewart/core/services';

// const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))({
//   [`& .${tooltipClasses.tooltip}`]: {
//     maxWidth: 260,
//   },
// });

type StewartSelectProps = {
  placeholder?: string;
  name: string;
  options: any[];
  optionsTextField?: string;
  optionsValueField?: string;
  disabled?: boolean;
  onChange?: (e: any, item: any) => void;
  controlStyle?: any;
  readOnly?: boolean;
  isShowSelect?: boolean;
  permissionCode?: string;
  inputClassName?: any;
  isDisableOption?: (option?: any) => boolean;
  caseInsensitiveSelection?: boolean;
  // isTooltipEnabled?: boolean;
};

// Component
const StewartSelect = ({
  placeholder,
  name,
  optionsTextField = 'label',
  optionsValueField = 'value',
  options,
  onChange,
  controlStyle,
  readOnly,
  isShowSelect,
  disabled,
  permissionCode,
  inputClassName,
  isDisableOption,
  caseInsensitiveSelection = false,
}: // isTooltipEnabled,
StewartSelectProps) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldAttributes<any>) => {
        const { errors, touched, setFieldValue } = form;
        let { value: fieldValue } = field;
        const isTouched = getIn(touched, name);
        const errorMessage = getIn(errors, name);
        const isInvalid = Boolean(isTouched && errorMessage);
        const { permissionCodes } = useSelector<RootStateType, PermissionStore>(
          (state) => state.permission
        );
        if (caseInsensitiveSelection) {
          const pattern = new RegExp(`^${fieldValue}$`, 'i');
          const selectOption = options.find((item) => pattern.test(item[optionsValueField]));
          fieldValue = selectOption ? selectOption[optionsValueField] : fieldValue;
        }
        const defaultOption = options.length === 1 ? options[0][optionsValueField] : null;

        useEffect(() => {
          if (defaultOption && !fieldValue) {
            setFieldValue(name, defaultOption);
          }
        }, [options]);

        return (
          <FormControl
            error={isInvalid}
            style={{ marginTop: '15px', marginBottom: '30px', ...controlStyle }}
          >
            <InputLabel style={{ marginLeft: '-2px' }} htmlFor={name}>
              {placeholder}
            </InputLabel>
            <Select
              {...field}
              value={fieldValue}
              labelId={name}
              id={name}
              label={placeholder}
              onChange={onChange}
              readOnly={readOnly}
              size="small"
              className={inputClassName ? `select ${inputClassName}` : 'select'}
              disabled={(permissionCode && !permissionCodes.includes(permissionCode)) || disabled}
              IconComponent={DropdownIcon}
              // MenuProps={{ sx: { width: isTooltipEnabled && 300 } }}
            >
              {isShowSelect && (
                <MenuItem key={uuidv4()} value="" className="select-field">
                  {label('lbl_select')}
                </MenuItem>
              )}
              {options.map((option, ind) => {
                const optionValue = option[optionsValueField];
                return (
                  <MenuItem
                    key={uuidv4()}
                    value={optionValue}
                    disabled={isDisableOption?.(option)}
                    sx={{
                      height: ind === 0 && option[optionsTextField] === '' ? '32.57px' : 'unset',
                    }}
                  >
                    {option[optionsTextField]}
                  </MenuItem>
                );
                // return isTooltipEnabled ? (
                //   <MenuItem
                //     key={uuidv4()}
                //     value={optionValue}
                //     disabled={isDisableOption?.(option)}
                //     sx={{ whiteSpace: 'nowrap' }}
                //   >
                //     <CustomWidthTooltip
                //       title={option[optionsTextField]}
                //       key={uuidv4()}
                //       placement="top"
                //       arrow
                //       PopperProps={{
                //         modifiers: [
                //           {
                //             name: 'offset',
                //             options: {
                //               offset: [0, -10],
                //             },
                //           },
                //         ],
                //       }}
                //     >
                //       <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                //         {option[optionsTextField]}
                //       </div>
                //     </CustomWidthTooltip>
                //   </MenuItem>
                // ) : (
                //   <MenuItem key={uuidv4()} value={optionValue} disabled={isDisableOption?.(option)}>
                //     {option[optionsTextField]}
                //   </MenuItem>
                // );
              })}
            </Select>

            <MaybeComponent isVisible={isInvalid}>
              <FormHelperText style={{ marginLeft: '12px', position: 'absolute', top: '35px' }}>
                {errorMessage}
              </FormHelperText>
            </MaybeComponent>
          </FormControl>
        );
      }}
    </Field>
  );
};

StewartSelect.defaultProps = {
  optionsTextField: 'label',
  optionsValueField: 'value',
  disabled: false,
  onChange: () => {},
  controlStyle: {},
  readOnly: false,
  placeholder: '',
  isShowSelect: false,
  permissionCode: '',
  inputClassName: '',
  isDisableOption: () => false,
};

export default StewartSelect;
