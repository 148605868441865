import { apiUrlStorage } from '@stewart/core/services/apiUrlStorage.service';

/**
 * Composes Rest Url
 * @param {string} endpoint
 */
export function composeUrl(endpoint: string): string {
  return `${apiUrlStorage.read()}/api/${endpoint}`;
}

export function composeUrlFromLocal(endpoint: string): string {
  const customUrl =
    window.location.hostname === 'localhost'
      ? 'https://azapp-web-stewartconnect-dev-02.azurewebsites.net'
      : window.location.origin;

  return `${customUrl}/api/${endpoint}`;
}

export function composeUrlFromLocal2(endpoint: string): string {
  const customUrl =
    window.location.hostname === 'localhost'
      ? 'https://dev-admin.stewartconnect.com'
      : window.location.origin;

  return `${customUrl}/api/${endpoint}`;
}
