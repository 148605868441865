export const colors = {
  activeBlue: '#0075aa',
  activeBlueDisabled: '#558FAA',
  darkBlue: '#005b84',
  grey: '#808080',
  grey100: '#e5e5e5',
  grey150: '#f5f4f4',
  grey200: '#f2f2f2',
  grey300: '#e8e5e5',
  grey400: '#e5e5e5',
  grey500: '#c7cdd5',
  grey600: '#adadad',
  grey900: '#626262',
  grey1000: '#797979',
  lightMaroon: '#9e2339',
  black: '#000000',
  white: '#ffffff',
  errorColor: '#E00000',
  successColor: '#2e7d32',
  backgroundSuccess: '#e8f2e7',
  orange: '#ff9933',
  mainText: '#212121',
  lightBlue: '#E6F1F7',
  aliceBlue: '#529dc7',
  deactivateGrey: '#D3D3D3',
};

export const gradients = {
  actionBlueGradient: 'linear-gradient(#189AD4, #0075AA)',
  actionBlueGradientDisabled: 'linear-gradient(#6AB3D4, #558FAA)',
  actionBlueGradientDark: 'linear-gradient(#0075AA, #004D82)',
  actionBlueGradientDarkDisabled: 'linear-gradient(#004D82, #416882)',
  errorGradient: 'linear-gradient(#E00000, #860000)',
  darkGreyGradient: 'linear-gradient(#7F7F7F, #555555)',
  successGreenGradient: 'linear-gradient(#00875A, #006E49)',
  yellowWarningGradient: 'linear-gradient(#FFC400, #FFAB00)',
};

export default colors;
