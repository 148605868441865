import axios from 'axios';

import { GET_AUTH_CONFIGURATION_API_PATH } from '@stewart/core/rest/api-paths';
import { AuthConfigType } from '@stewart/core/rest/models/configuration.models';

/**
 * Return App Config
 * @return {Promise<AuthConfigType>}
 */
export function getConfiguration(): Promise<AuthConfigType> {
  // Notes: http address for DEV and QA environment:
  // localhost DEV: 'https://azapp-web-stewartconnect-dev-02.azurewebsites.net'
  // localhost QA: 'https://azapp-web-stewartconnect-qa-02.azurewebsites.net'

  return axios
    .get(
      `${
        window.location.hostname === 'localhost'
          ? 'https://azapp-web-stewartconnect-dev-02.azurewebsites.net'
          : window.location.origin
      }/${GET_AUTH_CONFIGURATION_API_PATH}`
    )
    .then((response: { data: AuthConfigType }) => response.data);
}
