import { createTheme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

import { colors, gradients } from '@stewart/theme/colors';

import { zIndex, defaultStyle } from './defaultStyle';

declare module '@mui/material/styles' {
  /* eslint-disable no-unused-vars */
  interface Theme {
    custom: {
      common: any;
      container: any;
      textField: any;
    };
  }

  interface ThemeOptions {
    custom?: {
      common?: any;
      container?: any;
      textField?: any;
    };
  }

  /* eslint-enable no-unused-vars */
}
const { label, control } = defaultStyle;
const custom = {
  textField: {
    labelPlaceholder: {
      zIndex: zIndex.positiveMin,
      paddingLeft: `${label.inner.paddingLeft}`,
      color: label.inner.color,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '90%',
      height: '100%',
      pointerEvents: 'none',
      paddingRight: `${label.inner.paddingRight}`,
    },
    label: {
      zIndex: zIndex.positiveMin,
      paddingLeft: `${label.inner.paddingLeft}`,
      color: label.inner.color,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
      paddingRight: `${label.inner.paddingRight}`,
    },
    labelFocus: {
      color: label.outer.color,
      overflow: 'unset',
    },
    labelShrink: {
      transform: `translate(0, -2px) scale(0.78)`,
      color: label.outer.color,
      paddingLeft: `${label.outer.paddingLeft}`,
    },
    // Input
    input: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      borderRadius: `${control.borderRadius}`,
      paddingLeft: `${control.paddingLeft}`,
      paddingRight: `${control.paddingRight} !important`,
      paddingTop: `${control.paddingTop}`,
      paddingBottom: `${control.paddingBottom}`,
      backgroundColor: control.backgroundColor,
      border: `${control.borderSize} solid ${control.borderColor}`,
      height: `${control.height}`,
    },
    inputDate: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      paddingLeft: '6px',
      paddingRight: `${control.paddingRight}`,
      paddingTop: `${control.paddingTop}`,
      paddingBottom: `${control.paddingBottom}`,
      backgroundColor: control.backgroundColor,
      marginLeft: '6px',
    },
    inputHover: {
      border: `${control.borderSize} solid ${control.borderHoverColor}`,
    },
    inputFocus: {
      border: `${control.borderSize} solid ${control.borderFocusColor}`,
    },
    inputDisabled: {
      color: control.disabled.color,
      backgroundColor: control.disabled.backgroundColor,
      padding: 0,
      paddingLeft: '6px',
      paddingRight: `${control.paddingRight}`,
      height: '36px',
      '&:hover': {
        border: `${control.borderSize} solid ${control.borderColor}`,
      },
    },
    inputOutlined: {
      backgroundColor: control.backgroundColor,
      borderRadius: control.borderRadius,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    inputOutlinedDisabled: {
      color: control.disabled.color,
      backgroundColor: control.disabled.backgroundColor,
      borderRadius: control.borderRadius,
    },
    // AutoComplete
    autoComplete: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      borderRadius: `${control.borderRadius}`,
      paddingLeft: `${control.paddingLeft}`,
      paddingRight: `${control.paddingRight}`,
      paddingTop: `0px`,
      paddingBottom: `0px`,
      backgroundColor: control.backgroundColor,
      border: `${control.borderSize} solid ${control.borderColor}`,
    },
    autoCompleteHover: {
      border: `${control.borderSize} solid ${control.borderHoverColor}`,
    },
    autoCompleteFocus: {
      border: `${control.borderSize} solid ${control.borderHoverColor}`,
    },

    // DropDownList (Select)
    select: {
      overflow: 'hidden',
      borderRadius: `${control.borderRadius}`,
      paddingLeft: `${control.paddingLeft}`,
      paddingRight: `${control.paddingRight}`,
      paddingTop: `${control.paddingTop}`,
      paddingBottom: `${control.paddingBottom}`,
      backgroundColor: control.backgroundColor,
      border: `${control.borderSize} solid ${control.borderColor}`,
      height: '26px',
      fontSize: `${control.fontSize}`,
      lineHeight: '26px',
      // transition: defaultTheme.transitions.create(["border-color", "box-shadow"])
    },
    selectHover: {
      border: `${control.borderSize} solid ${control.borderHoverColor}`,
    },
    selectFocus: {
      borderColor: `${control.borderFocusColor}`,
    },
    selectDisabled: {
      color: control.disabled.color,
      backgroundColor: control.disabled.backgroundColor,
      border: 'none',
    },
    selectError: {
      border: `${control.borderSize} solid ${control.errorColor}`,
    },
    colorError: {
      color: control.errorColor,
    },
    arrowError: {
      fill: control.errorColor,
    },
  },
};
const palette = {
  primary: {
    main: colors.activeBlue,
    dark: colors.darkBlue,
  },
  secondary: {
    main: colors.grey500,
    light: colors.grey200,
    dark: colors.grey600,
    contrastText: colors.grey900,
  },
  background: {
    default: colors.grey400,
  },
  error: {
    main: colors.errorColor,
  },
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 360,
    md: 768,
    lg: 1280,
    xl: 1920,
  },
};

const typography = {
  h3: {
    fontWeight: 700,
    fontSize: '36px',
  },
  h4: {
    fontWeight: 700,
    fontSize: '14px',
  },
  h6: {
    fontWeight: 700,
    fontSize: '18pt',
    marginBottom: '0px',
  },
  fontSize: 12,
  fontFamily: "'Roboto', sans-serif",
};

const components: Components = {
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: colors.activeBlue,
          color: colors.white,
          '&:hover': {
            backgroundColor: colors.activeBlue,
            color: colors.white,
          },
        },
        '&:hover': {
          backgroundColor: colors.activeBlue,
          color: colors.white,
        },
        '&.columns:hover': {
          backgroundColor: 'inherit',
          color: colors.white,
        },
        '&.select-field:not(.Mui-selected)': {
          color: colors.grey,
          '&:hover': {
            color: colors.white,
          },
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        fontSize: '14px',
        fontWeight: '500',
        border: '1px solid transparent',
        borderRadius: '4px',

        '& fieldset': {
          borderColor: colors.grey200,
          borderWidth: '1px!important',
        },

        '&:hover fieldset': {
          borderColor: `${colors.activeBlue}!important`,
        },

        '& input, .MuiSelect-select': {
          padding: '8px 12px',
          borderRadius: '4px',
        },

        '& input:disabled': {
          backgroundColor: colors.grey200,
          color: colors.grey900,
          overflow: 'hidden',
        },

        '&.select fieldset legend span': {
          display: 'none',
        },

        '&.select .Mui-disabled': {
          backgroundColor: colors.grey200,
        },

        '&.Mui-disabled fieldset': {
          borderColor: 'transparent!important',
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        width: '100%',

        '& label': {
          marginTop: '-7px',
          color: colors.grey600,

          '&.MuiInputLabel-shrink': {
            color: colors.black,
          },

          '& .MuiTypography-root': {
            color: colors.black,
            fontSize: '14px',
          },
        },
        '& fieldset legend': {
          width: 0,
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        transform: 'scale(1.15)',

        '&$disabled': {
          color: colors.grey200,
          backgroundColor: 'transparent',
          borderRadius: '2px',
        },
      },
    },
  },
  MuiButton: {
    variants: [
      {
        props: { variant: 'contained' },
        style: {
          background: gradients.actionBlueGradientDark,
          color: colors.white,

          '&:hover': {
            background: gradients.actionBlueGradient,
          },
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          backgroundColor: colors.white,
          color: colors.activeBlue,
          border: `1px solid ${colors.activeBlue}`,
          boxShadow:
            '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',

          '&:hover': {
            color: colors.darkBlue,
            border: `1px solid ${colors.darkBlue}`,
            backgroundColor: colors.white,
            boxShadow:
              '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        textTransform: 'none',
        padding: '5px 15px',
        fontWeight: 700,
        fontSize: '14px',
        minWidth: '80px',

        '&.Mui-disabled': {
          background: gradients.actionBlueGradientDisabled,
          color: colors.white,
        },
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        '.MuiTableContainer-root': {
          maxHeight: 'calc(100vh - 455px)',
          minHeight: '395px',
        },
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        fontSize: '14pt',
        fontWeight: 700,
        color: colors.white,
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        width: '99%',
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        backgroundColor: 'white',
        '&.Mui-selected': {
          backgroundColor: 'white',
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: '12px 16px',

        '&:last-child': {
          borderRight: `none`,
        },
      },
    },
  },
  MuiTableSortLabel: {
    styleOverrides: {
      root: {
        '&:not(.Mui-active):hover': {
          '.grid-sort-icon': {
            visibility: 'visible',
          },
        },

        '&.Mui-active': {
          '.MuiTableSortLabel-icon.grid-sort-icon': {
            opacity: '1 !important',
          },
        },

        '.MuiTableSortLabel-icon.grid-sort-icon': {
          opacity: '0.5 !important',
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {},
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {},
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
          padding: '2px 4px 2px 6px',
        },

        '.MuiAutocomplete-endAdornment': {
          top: 'calc(50% - 12px)',
        },

        '.MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
          marginTop: '0px',
        },

        '.Mui-disabled': {
          backgroundColor: colors.grey200,
        },
      },
    },
  },
};

const theme = createTheme({
  palette,
  breakpoints,
  typography,
  components,
  custom,
});

export default theme;
